<template>
  <Row class="px-1">
    <i-col
      span="24"
      style="font-size:18px;font-weight:bold;text-align:center;padding-bottom:8px"
    >盛望医疗测评报告</i-col>
    <i-col
      span="24"
      class="align-justify"
      style="font-size:20px;font-weight:bold;text-align:center;padding-bottom:8px"
    >Achenbach儿童行为量表（CBCL）</i-col>
    <i-col span="24" style="font-size:16px;font-weight:bold;padding-bottom:8px;float:left">基本信息：</i-col>
    <i-col span="8" class="align-justify" style="padding-bottom:8px">编号：1001</i-col>
    <i-col span="8" class="align-justify" style="padding-bottom:8px">姓名：测试者</i-col>
    <i-col span="8" class="align-justify" style="padding-bottom:8px">性别：男</i-col>
    <i-col span="8" class="align-justify" style="padding-bottom:8px">年龄：12</i-col>
    <i-col span="8" class="align-justify" style="padding-bottom:8px">测试时间：2020-10-29</i-col>
    <i-col span="8" class="align-justify" style="padding-bottom:8px">测试耗时：12:01:22</i-col>
    <i-col span="24" style="font-size:16px;font-weight:bold;padding-bottom:8px">测评结果：</i-col>
    <i-col span="24" style="padding-bottom:8px">
      <Table :columns="columns1" :data="data1"></Table>
    </i-col>
    <i-col span="24" style="font-size:16px;font-weight:bold;padding-bottom:8px">分析建议：</i-col>
    <i-col span="4" style="padding-bottom:8px">分裂样</i-col>
    <i-col span="20" style="padding-bottom:8px">
      该儿童胆小，害怕上学以及某些动物或场景，容易做噩梦，有时还会出现其他异常行为或想法；还有可能看到实际不存在的事物，或者听到实际不存在的声音。
      建议家长带该儿童到精神科门诊进一步诊断。
      <div id="visitor-volume"></div>
    </i-col>
    <i-col span="4" style="padding-bottom:8px">抑郁</i-col>
    <i-col span="20" style="padding-bottom:8px">
      该儿童存在抑郁倾向，常处于悲伤、忧虑、内疚、孤独的情绪中，容易激动和紧张，感觉自己没用，担心做错事，担心自己不被他人喜欢；也有可能对他人不信任，不合群。抑郁的原因是多方面的，然而儿童抑郁家庭因素是主要方面，比如父母关系不和，养育方式存在较多问题。
      建议关注家庭因素，如婚姻关系、养育方式，还需要进一步了解该儿童在学校的情况。
    </i-col>
    <i-col span="4" style="padding-bottom:8px">交往不良</i-col>
    <i-col span="20" style="padding-bottom:8px">
      该儿童在人际交往中主动性不足，喜欢独来独往，交流中感到不自然或发窘，或者茫然凝视，容易激怒，固执；可能存在抑郁症状。
      该儿童可能性格偏内向，交流时应注意这一点，并给家长提供相关的建议。
    </i-col>
    <i-col span="4" style="padding-bottom:8px">强迫性</i-col>
    <i-col span="20" style="padding-bottom:8px">
      该儿童存在一些强迫观念无法摆脱，有一些强迫行为无法控制；可能处于情绪紧张之中。要求过高、过严，时常训斥，容易导致儿童紧张焦虑，长期处于情绪紧张之中，容易诱发强迫观念或强迫行为。
      教给儿童一些放松的方法，比如腹式呼吸放松法，肌肉放松法，让儿童获得自我调节紧张情绪的能力，要考虑儿童的年龄，选择合适的方法。
    </i-col>
  </Row>
</template>

<script>
import { Chart } from "@antv/g2";
export default {
  name: "ScalesDossiers",
  data() {
    return {
      columns1: [
        {
          title: "因子",
          key: "name",
        },
        {
          title: "分数",
          key: "age",
        },
        {
          title: "参考值",
          key: "address",
        },
        {
          title: "结果",
          key: "date",
        },
      ],
      data1: [
        {
          name: "活动能力",
          age: 4,
          address: "-",
          date: "-",
        },
        {
          name: "社交能力",
          age: 2,
          address: "-",
          date: "-",
        },
        {
          name: "学习能力",
          age: 4,
          address: "-",
          date: "-",
        },
        {
          name: "分裂样",
          age: 12,
          address: "6",
          date: "异常",
        },
        {
          name: "抑郁",
          age: 26,
          address: "10",
          date: "异常",
        },
        {
          name: "交往不良",
          age: 11,
          address: "6",
          date: "异常",
        },
        {
          name: "强迫性",
          age: 21,
          address: "9",
          date: "异常",
        },
      ],
    };
  },
  mounted() {
    console.log(window.innerHeight);
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.initVolumeContent();
  },
  methods: {
    // 初始化访问量数据视图
    initVolumeContent() {
      const data = [
        { month: "1月", sales: 9728 },
        { month: "2月", sales: 4578 },
        { month: "3月", sales: 12768 },
        { month: "4月", sales: 4928 },
        { month: "5月", sales: 9665 },
        { month: "6月", sales: 5486 },
        { month: "7月", sales: 2358 },
        { month: "8月", sales: 13547 },
        { month: "9月", sales: 6587 },
        { month: "10月", sales: 5487 },
        { month: "11月", sales: 7548 },
        { month: "12月", sales: 5658 },
      ];
      const chart = new Chart({
        container: "visitor-volume",
        autoFit: true,
        height: 240,
      });

      chart.data(data);
      chart.scale("sales", {
        alias: "访问量",
        nice: true,
      });
      chart.tooltip({
        showCrosshairs: true,
      });
      // let barWidth = 65 * (window.innerWidth / 1960);
      chart
        .interval()
        .position("month*sales")
        .color("l(90) 0:#68cdff 1:#1495eb")
        .style({ radius: [3, 3, 0, 0] });
      chart.render();
    },
  },
};
</script>

<style>
/* .visitor-volume-content {
  margin-top: 20px;
  width: 100%;
  background-color: #fff;

  .content {
    display: flex;
    padding: 14px 16px;
    justify-content: space-between;
    .content-left {
      width: 65%;
      h4 {
        text-align: left;
      }
      #visitor-volume {
        padding: 20px 12px 0;
      }
    }
    .content-right {
      width: 32%;
      h4 {
        text-align: left;
      }
      .ranking {
        .ranking-content {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          .order {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ivu-modal {
  top: 0;
}

.ivu-table th,
.ivu-table td {
  border: none;
}
.ivu-table th {
  background: #fff;
}
.ivu-table:before {
  background-color: #fff;
} */
</style>
